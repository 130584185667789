<template>
  <div>
    <div class="split overridesplitleft left">
      <img
        src="@/assets/images/logo/logo-prism.png"
        alt="asd"
        style="width:400px;height:250px;"
      >
    </div>

    <div class="d-flex flex-column justify-content-center split overridesplitright right">
      <div
        class="screensize"
        style="margin:auto;"
      >
        <div class="d-flex col-lg-12 col-md-12 col-sm-12 mb-2">
          <span style="font-size: clamp(28px,5vw,36px); color:black;"><strong>Update your password</strong></span>
        </div>

        <div class="d-flex col-lg-12 col-md-12 col-sm-12 mb-1">
          <span style="font-size: clamp(16px,5vw,24px); "><strong>Enter code that we sent to the email ending</strong></span>
        </div>
        <div class="d-flex col-lg-12 col-md-12 col-sm-12 mb-1">
          <span style="font-size: clamp(16px,5vw,24px); "><strong>{{ obsecure }}</strong></span>
        </div>

        <div class="d-flex col-lg-12 col-md-12 col-sm-12 mb-2 " />

        <div class="form-inline mb-3 justify-content-center test">
          <input
            id="satu"
            class="form-control bg-white text-center code mb-1"
            type="text"
            value=""
            name="satu"
            autocomplete="off"
            maxlength="1"
            @keyup="autoline"
          >
          <input
            id="dua"
            class="form-control bg-white text-center code mb-1"
            type="text"
            value=""
            name="dua"
            autocomplete="off"
            maxlength="1"
            @keyup="autoline"
          >
          <input
            id="tiga"
            class="form-control bg-white text-center code mb-1"
            type="text"
            value=""
            name="tiga"
            autocomplete="off"
            maxlength="1"
            @keyup="autoline"
          >
          <input
            id="empat"
            class="form-control bg-white text-center code mb-1"
            type="text"
            value=""
            name="empat"
            autocomplete="off"
            maxlength="1"
            @keyup="autoline"
          >
          <input
            id="lima"
            class="form-control bg-white text-center code mb-1"
            type="text"
            value=""
            name="lima"
            autocomplete="off"
            maxlength="1"
            @keyup="autoline"
          >
          <input
            id="enam"
            class="form-control bg-white text-center code mb-1"
            type="text"
            value=""
            name="enam"
            autocomplete="off"
            maxlength="1"
            @keyup="autoline"
          >
        </div>

        <div
          class="d-flex justify-content-between"
          style="font-size: clamp(14px,5vw,18px);"
        >
          <router-link
            :to="{name:'forgot-password'}"
            class=""
            style="color:#000;"
          ><i
            class="fas fa-long-arrow-alt-left"
            style="color:#000;"
          />&nbsp;&nbsp;Back</router-link>
          <a
            href=""
            class=""
            style="color:#2F80ED; text-align: right;"
          >Resend Code</a>
        </div>
      </div>
    </div>

  </div>

</template>
<style scoped>
    .split {
      height: 100%;
      width:50%;
      position: fixed;
      z-index: 1;
      top: 0;
      overflow-x: hidden;
      padding-top: 20px;
    }

    .left {
      background: url('~@/assets/images/background/login.png');
      background-repeat: no-repeat;
      background-size: auto 100%;
      /* background-image: url('~@/assets/images/background/login.png');
      height: 100%; */
      background-position: center;
    }
    .right {
      right: 0;
      background-color: white;
    }

    .centered {
      position: relative;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      padding: 100px;
      text-align: center;
    }

    .centered img {
      width: 150px;
      border-radius: 50%;
    }

    .code{
      -webkit-appearance: none!important;
      -moz-appearance: textfield!important;
      border-radius: 6px 6px;
      border-width: 2px;
      -webkit-box-sizing: border-box;
      -moz-box-sizing: border-box;
      box-sizing: border-box;
      -webkit-box-flex: 1;
      -webkit-flex: 1 1 auto;
      -moz-box-flex: 1;
      -ms-flex: 1 1 auto;
      flex: 1 1 auto;
      font-size: 34px;
      font-weight: lighter;
      height: 80px;
      margin-left: 4%;
      min-width: 20px;
      max-width: 52px;
      text-align: center;
      -o-text-overflow: clip;
      text-overflow: clip;
    }

    .screensize{
      width: 48%;
    }

    /* Split screen */
    @media screen and (max-width: 1100px) {
        .overridesplitright{
          width: 100% !important;
        }

        .overridesplitleft{
          display: none !important;
        }
    }
    /* End Split screen */
    @media screen and (max-width: 2000px) {
        .screensize{
          width: 48%;
        }

        .code{
          font-size:18px;
          height: 72px;
          margin-left: 3%;
          width: 10px;
        }
    }
    @media screen and (max-width: 1700px) {
      .screensize{
        width: 56%;
      }

      .code{
        font-size:18px;
        height: 72px;
        margin-left: 3%;
        width: 10px;
      }
    }
    @media screen and (max-width: 1400px) {
      .screensize{
        width: 66%;
      }

      .code{
        font-size:18px;
        height: 72px;
        margin-left: 3%;
        width: 10px;
      }
    }
    @media screen and (max-width: 1200px) {
      .screensize{
        width: 76%;
      }

      .code{
        font-size:18px;
        height: 72px;
        margin-left: 3%;
        width: 10px;
      }
    }
    @media screen and (max-width: 1100px) {
      .screensize{
        width: 76%;
      }

      .code{
        font-size:18px;
        height: 72px;
        margin-left: 3%;
        width: 10px;
      }
    }
    @media screen and (max-width: 800px) {
      .screensize{
        width: 56%;
      }

      .code{
        font-size:18px;
        height: 72px;
        margin-left: 3%;
        width: 10px;
      }
    }
    @media screen and (max-width: 500px) {
      .screensize{
        width: 76%;
      }

      .code{
        font-size:12px;
        height: 62px;
        margin-left: 2%;
        width: 10px;
      }
    }
    @media screen and (max-width: 400px) {
      .screensize{
        width: 76%;
      }

      .code{
        font-size:12px;
        height: 62px;
        margin-left: 2%;
        width: 10px;
      }
    }
    @media screen and (max-width: 300px) {
      .screensize{
        width: 86%;
      }

      .code{
        font-size:10px;
        height: 52px;
        margin-left: 2%;
        width: 10px;
      }
    }
</style>

<script>

export default {
  components: { },
  data() {
    return {
      email: "SarahPuzi@gmail.com",
      obsecure: "",
    }
  },

  beforeMount(){},
  mounted(){
    this.obsecureEmail()
  },
  methods: {
    autoline(e){
      const target = e.srcElement
      const maxLength = parseInt(target.attributes.maxlength.value, 10)
      const myLength = target.value.length
      if (myLength >= maxLength) {
        const next = target
        console.log(target)
        console.log(next)
        console.log(next.nextElementSibling)
        if (next.nextElementSibling !== null){
          next.nextElementSibling.focus()
          console.log("keluar")
        } else {
          console.log("tak keluar")
          this.$router.push({ name: 'reset-password' })
        }
      }
    },

    obsecureEmail(){
      const [name, domain] = this.email.split('@')
      console.log(name.length)
      this.obsecure = `${name[0]}${new Array(name.length - 1).join('*')}${name[name.length - 1]}@${domain}`
      return this.obsecure
    },

  },

}

</script>
